import styled from "styled-components";
import Button from "@material-ui/core/Button";

const SButton = styled(Button)`
  margin: 0rem 1rem;
  min-width: 10rem;
  ${(props: { width?: string }) => {
    if (props.width) {
      return `width: ${props.width};`;
    }
  }}
`;

export default SButton;
