import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

const Wrapper = styled(Paper)`
  padding: 3rem;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 2rem;
  }
`;

export default Wrapper;
