// React component with a form to create a new member of the health team
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import packService from "../../utils/api/v1/packService";
import { navigate } from "gatsby-link";

// typings
import Pack from "../../utils/interfaces/Pack";
import PackCategory from "../../utils/interfaces/PackCategory";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Flex from "../../components/Containers/Flex";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import Wrapper from "../../components/Containers/Wrapper";
import SButton from "../../components/Buttons/SButton";
import Error from "../../components/Errors/Error";
import PackData from "../../components/Pack/PackData";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { TextareaAutosize } from "@material-ui/core";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;

  ${(props: { margin?: string }) => {
    if (props.margin) {
      return `margin: ${props.margin};`;
    }
  }}

  .MuiFormControl-root {
    margin: 5px 10px;
  }
`;

const NewPack = (): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<PackCategory[]>([]);
  const [packData, setPackData] = useState<Pack>({
    active: false,
    category: "",
    cover_image_link: "",
    description: "",
    final_price: 0,
    name: "",
    number_of_patients: 0,
    results_time: 24,
    services: [],
    stackable: false,
    tag: "",
    web_display_order: 0,
    external_reference: "",
  });

  const fetchCategories = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await packService.fetchCategories();
      setCategories(res.data);
    } catch (err) {
      setErrors([err.message]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const errors = checkErrors();
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const req = await packService.createPack(packData);
      Swal.fire({
        icon: req.status === 200 ? "success" : "error",
        title:
          req.status === 200 ? "Pack creado con éxito" : "Error al crear pack",
        text:
          req.status === 200
            ? `Pack ${packData.name} creado`
            : "Error al crear el pack",
        showConfirmButton: true,
        didClose: () => {
          navigate(-1);
        },
      });
    } catch (err) {
      console.error(err);
      setErrors([err.message]);
    }
    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setPackData((prevState: Pack): Pack => {
      const newState = { ...prevState };
      newState[attribute] = newValue;
      return newState;
    });
  };

  const checkErrors = () => {
    const newErrors = [];

    // check for errors in the form
    if (!packData.name.length) {
      newErrors.push("El nombre es obligatorio");
    }
    if (packData.final_price <= 0) {
      newErrors.push("El precio final es incorrecto");
    }
    if (packData.results_time < 0) {
      newErrors.push("El tiempo de resultados es incorrecto");
    }
    if (packData.number_of_patients < 0) {
      newErrors.push("El número de pacientes es incorrecto");
    }
    if (!packData.description) {
      newErrors.push("La descripción es obligatoria");
    }
    if (!packData.tag) {
      newErrors.push("El tag es obligatorio");
    }
    if (!packData.category) {
      newErrors.push("La categoría es obligatoria");
    }

    setErrors(newErrors);
    return newErrors.length ? true : false;
  };

  return (
    <PrivateRoute>
      <Flex justify="center">
        <Wrapper variant="outlined">
          <h2>Crear nuevo pack</h2>

          <Error>
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </Error>

          <Row>
            <TextField
              id="name"
              onChange={formChange}
              value={packData.name}
              helperText="Nombre del pack"
              fullWidth
            />
            <TextField
              id="description"
              onChange={formChange}
              value={packData.description}
              helperText="Descripción"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="tag"
              onChange={formChange}
              value={packData.tag}
              helperText="Tag"
              fullWidth
            />
            <TextField
              id="final_price"
              type="number"
              onChange={formChange}
              value={packData.final_price}
              helperText="Precio final"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="cover_image_link"
              onChange={formChange}
              value={packData.cover_image_link}
              helperText="Link de la imagen de portada"
              fullWidth
            />
            <TextField
              id="results_time"
              type="number"
              onChange={formChange}
              value={packData.results_time}
              helperText="Tiempo de resultados"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="number_of_patients"
              type="number"
              onChange={formChange}
              value={packData.number_of_patients}
              helperText="Número de pacientes"
              fullWidth
            />
            <TextField
              id="web_display_order"
              type="number"
              onChange={formChange}
              value={packData.web_display_order}
              helperText="Posición en la web"
              fullWidth
            />
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="stackable">Stackable</InputLabel>
              <Select
                value={packData.stackable}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    stackable: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem key="true" value="true">
                  Si
                </MenuItem>
                <MenuItem key="false" value="false">
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="category">Categoría</InputLabel>
              <Select
                value={packData.category}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    category: e.target.value as string,
                  });
                }}
                fullWidth
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="active">Activo</InputLabel>
              <Select
                value={packData.active}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    active: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem key="true" value="true">
                  Si
                </MenuItem>
                <MenuItem key="false" value="false">
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <TextField
              id="external_reference"
              onChange={formChange}
              value={packData.external_reference}
              helperText="External reference"
              fullWidth
            />
          </Row>
          <Flex align="center" justify="center" margin="2rem 0rem 1rem 0rem">
            <SButton
              variant="outlined"
              color="secondary"
              disabled={loading}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </SButton>
            <SButton
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onSubmit}
            >
              Crear
            </SButton>
          </Flex>
        </Wrapper>
      </Flex>
    </PrivateRoute>
  );
};

export default NewPack;
