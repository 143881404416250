import api from "../api";
import { AxiosResponse } from "axios";
import qs from "qs";
import { GetItemsApiServiceProps, GetItemsNamesApiServiceProps, RequestResponse } from "../../interfaces/request";
import { CartItemObjectType } from "../../interfaces/cartItem";
import { GetRawDataItemPayload, GetRawDataItemsNamePayload } from "../../interfaces/Services";
import { parseItems } from "../../parser/services";

const packsBaseUrl = "exam-packs/packs";
const packCategoriesBaseUrl = "exam-packs/pack-category";
const servicesUrl = "services";

class PackService {
  createPack(data: Object): Promise<AxiosResponse> {
    return api.post(`${packsBaseUrl}/`, (data = data));
  }

  updatePack(id: string, data: Object): Promise<AxiosResponse> {
    return api.patch(`${packsBaseUrl}/${id}/`, (data = data));
  }

  deletePack(id: string): Promise<AxiosResponse> {
    return api.delete(`${packsBaseUrl}/${id}/`);
  }

  fetchPacks = async ({
    signal,
    params,
  }: GetItemsApiServiceProps): Promise<RequestResponse<CartItemObjectType[]>> => {
    const res = await api.get<GetRawDataItemPayload[]>(
      `/v2/packs/?${qs.stringify(params, {
        arrayFormat: "repeat",
        addQueryPrefix: false,
        encode: false,
        indices: false,
      })}`,
      { signal },
    );
    const data = parseItems(res.data.filter(({ price }) => price));
    return { data };
  }

  fetchItemNamesAndCategories = async ({ signal, params }: GetItemsNamesApiServiceProps): Promise<RequestResponse<GetRawDataItemsNamePayload[]>> => {
    const res = await api.get<GetRawDataItemsNamePayload[]>(
      `/v2/packs/?${qs.stringify(params, {
        arrayFormat: "repeat",
        addQueryPrefix: false,
        encode: false,
        indices: false,
      })}`,
      { signal },
    );
    const data = res.data;
    return { data };
  }

  fetchPacksBySource = async ({signal, params}: GetItemsApiServiceProps): Promise<AxiosResponse> => {
    const LAST_MILE_SALES_SOURCES = [
      "medicalima",
      "laboratoriochopo",
      "mediqo",
      "farmapronto",
      "fertygen",
      "grupomok",
      "somos",
    ];
    const { source } = params;
    const sources = [source]
    if (!LAST_MILE_SALES_SOURCES.includes(source)) {
      sources.push("marketplace")
    }
    const res = await api.get<GetRawDataItemPayload[]>(
      `/v2/packs/?${qs.stringify(params, {
        arrayFormat: "repeat",
        addQueryPrefix: false,
        encode: false,
        indices: false,
      })}`,
      { signal },
    );

    const data = parseItems(res.data.filter(({ price }) => price));
    return { data };
  }

  fetchPack(id: string): Promise<AxiosResponse> {
    return api.get(`${packsBaseUrl}/${id}/`);
  }

  fetchCategories(): Promise<AxiosResponse> {
    return api.get(`${packCategoriesBaseUrl}/`);
  }

  addServiceToPack(packId: string, data: Object): Promise<AxiosResponse> {
    return api.post(`${packsBaseUrl}/${packId}/add_lab_services/`, (data = data));
  }

  removeServiceFromPack(packId: string, data: Object): Promise<AxiosResponse> {
    return api.patch(`${packsBaseUrl}/${packId}/remove_lab_services/`, (data = data));
  }

  fetchServices(): Promise<AxiosResponse> {
    return api.get(`${servicesUrl}/`);
  }
}

const packService = new PackService();

export default packService;
