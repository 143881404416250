import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import Auth from "../../utils/auth/auth";

const auth = new Auth();

const PrivateRoute = ( props: any ): JSX.Element => {
  const [logged, setLogged] = useState<boolean>(false);
  useEffect(() => {
    // if not logged in, return him to index with an error message
    if (!auth.isLoggedIn()) {
      navigate('/?error=NotLoggedIn&redirect=' + location.pathname);
    } else {
      setLogged(true);
    }
  }, [])

  return (
    <>
      { logged && props.children }
    </>
  )
}

export default PrivateRoute;
